import { Complaint, Diagnosis, Who } from './request'
import { Sex } from './common'
import { FileDto, FileType } from './file'
import { EducationDto } from './education'

export enum PsychologistCost {
    c2000 = 'c2000',
    c2000_3000 = 'c2000_3000',
    c3000_4000 = 'c3000_4000',
    c4000 = 'c4000',
}

export type PsychologistInputDTO = {
    firstname: string
    lastname: string
    telegramId: string
    who: Who[]
    summary: string
    complaints: Complaint[]
    diagnosis: Diagnosis[]
    birthYear: number
    sex: Sex
    cost: PsychologistCost
    experience: number
    phone: string
    telegramUsername?: string
    isActive?: boolean
    isApproved?: boolean
    photoId?: number
    videoNoteId?: number
}

export type PsychologistDTO = PsychologistInputDTO & {
    id: number
    photo?: FileDto
    createdAt?: Date
    updatedAt?: Date
    videoNote?: FileDto
    educations?: EducationDto[]
}

export type PsychologistOutputByRequestDTO = PsychologistDTO & {
    intersectedComplaints: Complaint[]
}

export const psychologistDialog = {
    who: {
        me: 'Взрослый',
        child: 'Ребенок',
        family: 'Семья или пара',
    },
    cost: {
        c2000: 'До 2000₽',
        c2000_3000: '2000 — 3000₽',
        c3000_4000: '3000 — 4000₽',
        c4000: 'Более 4000₽',
    },
    sex: {
        man: 'Мужской',
        women: 'Женский',
    },
    complaints: {
        experiences: 'Апатия, тревога',
        self_esteem: 'Проблемы с самооценкой',
        relations: 'Сложности в отношениях с людьми',
        no_positive: 'Не хватает позитивных эмоций',
        work: 'Сложности на работе, с самореализацией',
        health: 'Психосоматика',
        hard: 'Кризисы (потеря близкого, насилие, другое)',
        habits: 'Вредные привычки, зависимости',
        sex: 'Неудовлетворённость в сексуальной жизни',
        unknown: 'Не могу сформулировать тему',
    },
    diagnosis: {
        depression: 'Депрессия',
        anxiety: 'Тревожное расстройство',
        anxiety_depression: 'Тревожно-депрессивное расстройство',
        okr: 'ОКР (обсессивно-компульсивное расстройство)',
        borderline: 'Пограничное расстройство личности',
        bipolar: 'БАР (биполярное расстройство)',
        panic_attacks: 'Панические атаки',
        schizophrenia: 'Шизофрения',
        epilepsy: 'Эпилепсия',
    },
}

export type PostPsychologistFile = {
    fileTelegramId: FileDto['telegramId']
    name: FileDto['name']
    url: FileDto['url']
    userTelegramId: PsychologistDTO['telegramId']
    type: FileType
}

export const psychologistPath = 'psychologist'
export const psychologistPhotoPath = 'photo'
export const psychologistEducationPath = 'education'
export const psychologistSessionPath = 'session/all'
