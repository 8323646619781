import React, { createContext, useContext } from 'react'
import { getApiClient } from './api-client'

type ApiContext = ReturnType<typeof getApiClient>
export const ApiContext = createContext<ApiContext>({} as ApiContext)

export const useApi = () => useContext(ApiContext)

export const ApiProvider = ({
    children,
    api,
}: React.PropsWithChildren<{ api: ReturnType<typeof getApiClient> }>) => {
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}
