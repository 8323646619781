import { Sex } from './common'
import { UserDto } from './user'

export enum Who {
    me = 'me',
    child = 'child',
    family = 'family',
}

export enum Complaint {
    experiences = 'experiences',
    self_esteem = 'self_esteem',
    relations = 'relations',
    no_positive = 'no_positive',
    work = 'work',
    health = 'health',
    hard = 'hard',
    habits = 'habits',
    sex = 'sex',
    unknown = 'unknown',
}

export enum Diagnosis {
    depression = 'depression',
    anxiety = 'anxiety',
    anxiety_depression = 'anxiety_depression',
    okr = 'okr',
    borderline = 'borderline',
    bipolar = 'bipolar',
    panic_attacks = 'panic_attacks',
    schizophrenia = 'schizophrenia',
    epilepsy = 'epilepsy',
}

export enum RequestSex {
    either = 'either',
    man = 'man',
    women = 'women',
}

export enum RequestCost {
    c2000 = 'c2000',
    c2000_3000 = 'c2000_3000',
    c3000_4000 = 'c3000_4000',
    c4000 = 'c4000',
    either = 'either',
}

export type RequestDto = {
    id: number
    who: Who
    complaints: Complaint[]
    diagnosis: Diagnosis[]
    age: number
    requestSex: RequestSex
    sex: Sex
    cost: RequestCost
    isActive?: boolean
    userId: number
    summary?: string
    user?: UserDto
    createdAt?: Date
}

export const requestStringMap = {
    who: {
        me: 'Для себя',
        child: 'Для ребёнка',
        family: 'Для семьи или для пары',
    },
    cost: {
        c2000: 'До 2000₽',
        c2000_3000: '2000 — 3000₽',
        c3000_4000: '3000 — 4000₽',
        c4000: 'Более 4000₽',
        either: 'Не важно',
    },
    requestSex: {
        man: 'Мужской',
        women: 'Женский',
        either: 'Не важно',
    },
    sex: {
        man: 'Мужской',
        women: 'Женский',
    },
    complaints: {
        experiences: 'Апатия, тревога',
        self_esteem: 'Проблемы с самооценкой',
        relations: 'Сложности в отношениях с людьми',
        no_positive: 'Не хватает позитивных эмоций',
        work: 'Сложности на работе, с самореализацией',
        health: 'Психосоматика',
        hard: 'Кризисы (потеря близкого, насилие, другое)',
        habits: 'Вредные привычки, зависимости',
        sex: 'Неудовлетворённость в сексуальной жизни',
        unknown: 'Не могу сформулировать тему',
    },
    diagnosis: {
        depression: 'Депрессия',
        anxiety: 'Тревожное расстройство',
        anxiety_depression: 'Тревожно-депрессивное расстройство',
        okr: 'ОКР (обсессивно-компульсивное расстройство)',
        borderline: 'Пограничное расстройство личности',
        bipolar: 'БАР (биполярное расстройство)',
        panic_attacks: 'Панические атаки',
        schizophrenia: 'Шизофрения',
        epilepsy: 'Эпилепсия',
    },
}

export const requestPath = 'request'
export const requestChoosePath = 'choose'
export const requestPsychologistPath = 'psychologist'

export type PutUserRequest = Omit<RequestDto, 'userId' | 'id'> & {
    telegramId: UserDto['telegramId']
}

export type ChoosePsychologistPayload = {
    userTelegramId: string
    psychologistId: number
}

export type SuitableRequest = RequestDto & {
    psychologistTelegramId: string
}
