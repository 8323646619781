import {
    PsychologistRespondDTO,
    PsychologistRespondInputDTO,
    psychologistRespondPath,
    PsychologistRespondShowedInput,
    psychologistRespondShowedPath,
} from '@psychology-bot/domain'
import { AxiosInstance } from 'axios'

export function savePsychologistRespond(
    apiClient: AxiosInstance,
    request: PsychologistRespondInputDTO,
) {
    return apiClient.post<PsychologistRespondDTO>('/' + psychologistRespondPath, request)
}

export function makePsychologistRespondShowed(
    apiClient: AxiosInstance,
    request: PsychologistRespondShowedInput,
) {
    return apiClient.post<true>(
        `/${psychologistRespondPath}/${psychologistRespondShowedPath}`,
        request,
    )
}

export function getPsychologistRespond(apiClient: AxiosInstance, respondId: string | number) {
    return apiClient.get<PsychologistRespondDTO>(`/${psychologistRespondPath}/${respondId}`)
}

export function getPsychologistRespondsByPsychologistId(
    apiClient: AxiosInstance,
    psychologistId: string | number,
) {
    return apiClient.get<PsychologistRespondDTO[]>(`/${psychologistRespondPath}/${psychologistId}`)
}

export function getPsychologistRespondsByRequestId(
    apiClient: AxiosInstance,
    requestId: string | number,
) {
    return apiClient.get<PsychologistRespondDTO[]>(
        `/${psychologistRespondPath}/request/${requestId}`,
    )
}
