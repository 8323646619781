import { PsychologistDTO } from './psychologist'
import { FileDto } from './file'

export enum EducationType {
    diploma = 'diploma',
    course = 'course',
}

export class EducationDto {
    id: number
    name: string
    issued: string
    year: number
    type: EducationType
    psychologistId: number
    psychologist?: PsychologistDTO
    photoId: number
    photo?: FileDto
    createdAt?: Date
    updatedAt?: Date
}

export type PostPsychologistEducation = Omit<
    EducationDto,
    'psychologistId' | 'psychologist' | 'photo' | 'photoId' | 'id'
> & {
    psychologistTelegramId: PsychologistDTO['telegramId']
    photoTelegramId: FileDto['telegramId']
    photoName: FileDto['name']
    photoUrl: FileDto['url']
}

export const educationTypeMap = {
    [EducationType.course]: 'Курсы',
    [EducationType.diploma]: 'Диплом',
}

export const educationEmojiMap = {
    [EducationType.course]: '📜',
    [EducationType.diploma]: '🎓',
}
