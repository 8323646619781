import { AxiosInstance } from 'axios'
import {
    ChoosePsychologistPayload,
    PutUserRequest,
    requestChoosePath,
    RequestDto,
    requestPath,
    requestPsychologistPath,
} from '@psychology-bot/domain'

export function choosePsychologist(apiClient: AxiosInstance, request: ChoosePsychologistPayload) {
    return apiClient.post<RequestDto>(`/${requestPath}/${requestChoosePath}`, request)
}

export function putRequest(apiClient: AxiosInstance, request: PutUserRequest) {
    return apiClient.put<RequestDto>(`/${requestPath}`, request)
}

export function getRequestsByPsychologist(apiClient: AxiosInstance, psychologistId: number) {
    return apiClient.get<RequestDto[]>(
        `/${requestPath}/${requestPsychologistPath}/${psychologistId}`,
    )
}
