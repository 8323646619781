import { userPath, UserDto, Session, userSessionPath } from '@psychology-bot/domain'
import { AxiosInstance } from 'axios'

export function postUser(apiClient: AxiosInstance, user: UserDto) {
    return apiClient.post<UserDto>(`/${userPath}`, user)
}

export function getUser(apiClient: AxiosInstance, telegramId: string) {
    return apiClient.get<UserDto>(`${userPath}/${telegramId}`)
}

export function getActiveUserSessions(apiClient: AxiosInstance) {
    return apiClient.get<Session[]>(`${userPath}/${userSessionPath}`)
}
