import {
    psychologistPath,
    PsychologistDTO,
    psychologistPhotoPath,
    PostPsychologistFile,
    psychologistEducationPath,
    PostPsychologistEducation,
    RequestDto,
    requestPath,
    PsychologistOutputByRequestDTO,
    psychologistSessionPath,
    Session,
} from '@psychology-bot/domain'
import { AxiosInstance } from 'axios'

export function postPsychologist(apiClient: AxiosInstance, request: Partial<PsychologistDTO>) {
    return apiClient.post<PsychologistDTO>(psychologistPath, request)
}

export function postPsychologistFile(apiClient: AxiosInstance, request: PostPsychologistFile) {
    return apiClient.post<PsychologistDTO>(`${psychologistPath}/${psychologistPhotoPath}`, request)
}

export function postPsychologistEducation(
    apiClient: AxiosInstance,
    request: PostPsychologistEducation,
) {
    return apiClient.post<PostPsychologistEducation>(
        `${psychologistPath}/${psychologistEducationPath}`,
        request,
    )
}

export function getPsychologist(apiClient: AxiosInstance, telegramId: string) {
    return apiClient.get<PsychologistDTO>(`${psychologistPath}/${telegramId}`)
}

export function getActivePsychologistSessions(apiClient: AxiosInstance) {
    return apiClient.get<Session[]>(`${psychologistPath}/${psychologistSessionPath}`)
}

export function deletePsychologistEducation(apiClient: AxiosInstance, educationId: number) {
    return apiClient.delete<number>(
        `${psychologistPath}/${psychologistEducationPath}/${educationId}`,
    )
}

export function getPsychologistsByRequest(apiClient: AxiosInstance, request: Partial<RequestDto>) {
    return apiClient.post<PsychologistOutputByRequestDTO[]>(
        `${psychologistPath}/${requestPath}`,
        request,
    )
}
