import { RequestDto } from './request'

export type UserDto = {
    name: string
    lastname?: string
    telegramId: string
    telegramUsername?: string
    createdAt?: Date
    updatedAt?: Date
    request?: RequestDto
}

export const userPath = 'user'
export const userSessionPath = 'session/all'
