export const botPsyPaths = {
    contacts: '/contacts',
    start: '/my_start',
    requests: '/requests',
}

export const botClientPaths = {
    myStart: '/my_start',
    request: '/request',
    requestAdd: '/request_add',
    psychologists: '/psychologists',
    contacts: '/contacts',
    responds: '/responds',
}
