import { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'

type QueryState<T, E = ServerError> = {
    isLoading: boolean
    isCalled: boolean
    isError: boolean
    isSuccess: boolean
    error?: E
    data?: T
    statusCode?: number
}
export type Query<T, E = ServerError, A = any> = QueryState<T, E> & {
    fetch: (...arg: A[]) => Promise<QueryState<T, E>>
}

export type ServerError = {
    statusCode: number
    timestamp: string
    path: string
    message: string
}

export type QueryOptions = {
    isLazy?: boolean
}

export function useQuery<T, A, E extends ServerError = ServerError>(
    id: string,
    callback: (...arg: A[]) => Promise<AxiosResponse<T>>,
    { isLazy = false }: QueryOptions = {},
): Query<T, E, A> {
    const [query, setQuery] = useState<QueryState<T, E>>({
        isLoading: !isLazy,
        isCalled: !isLazy,
        isError: false,
        isSuccess: false,
    })

    const fetch = useCallback(
        async function fetch(...arg: A[]): Promise<QueryState<T, E>> {
            setQuery({
                isLoading: true,
                isCalled: true,
                isError: false,
                isSuccess: false,
            })
            try {
                const response = await callback(...arg)
                const query = {
                    data: response.data,
                    statusCode: response.status,
                    isLoading: false,
                    error: undefined,
                    isCalled: true,
                    isError: false,
                    isSuccess: true,
                }
                setQuery(query)

                return query
            } catch (e) {
                const commonQuery = {
                    isLoading: false,
                    isCalled: true,
                    isError: true,
                    isSuccess: false,
                }

                if (e instanceof AxiosError) {
                    const query = {
                        ...commonQuery,
                        statusCode: e.response?.data?.statusCode,
                        error: e.response?.data,
                    }

                    setQuery(query)

                    return query
                }

                const query: any = {
                    ...commonQuery,
                    statusCode: undefined,
                    error: {
                        message: 'Не известная ошибка',
                        path: '',
                        statusCode: 500,
                        timestamp: String(Date.now()),
                    },
                }

                setQuery(query)

                return query
            }
        },
        [callback],
    )

    useEffect(() => {
        if (isLazy) {
            return
        }

        fetch()
    }, [id])

    return useMemo(() => ({ ...query, fetch }), [fetch, query])
}
