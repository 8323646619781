import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getActiveUserSessions, getUser, postUser } from './user'
import {
    deletePsychologistEducation,
    getActivePsychologistSessions,
    getPsychologist,
    getPsychologistsByRequest,
    postPsychologist,
    postPsychologistEducation,
    postPsychologistFile,
} from './psychologist'
import { choosePsychologist, getRequestsByPsychologist, putRequest } from './request'
import {
    getPsychologistRespondsByRequestId,
    getPsychologistRespondsByPsychologistId,
    savePsychologistRespond,
    getPsychologistRespond,
    makePsychologistRespondShowed,
} from './psychologistRespond'

export type ApiClientConfig = AxiosRequestConfig & {
    interceptors?: {
        responseError?: (error: AxiosError) => void
    }
}

export function getApiClient({ interceptors, ...config }: ApiClientConfig) {
    const apiClient = axios.create(config)

    apiClient.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            interceptors?.responseError?.(error)

            return Promise.reject(error)
        },
    )

    return {
        user: {
            postUser: postUser.bind(null, apiClient),
            getUser: getUser.bind(null, apiClient),
            getActiveUserSessions: getActiveUserSessions.bind(null, apiClient),
        },
        psychologist: {
            getPsychologist: getPsychologist.bind(null, apiClient),
            getActivePsychologistSessions: getActivePsychologistSessions.bind(null, apiClient),
            postPsychologist: postPsychologist.bind(null, apiClient),
            postPsychologistEducation: postPsychologistEducation.bind(null, apiClient),
            postPsychologistFile: postPsychologistFile.bind(null, apiClient),
            deletePsychologistEducation: deletePsychologistEducation.bind(null, apiClient),
            getPsychologistsByRequest: getPsychologistsByRequest.bind(null, apiClient),
        },
        request: {
            putRequest: putRequest.bind(null, apiClient),
            choosePsychologist: choosePsychologist.bind(null, apiClient),
            getRequestsByPsychologist: getRequestsByPsychologist.bind(null, apiClient),
        },
        psychologistRespond: {
            getPsychologistRespondsByRequestId: getPsychologistRespondsByRequestId.bind(
                null,
                apiClient,
            ),
            getPsychologistRespondsByPsychologistId: getPsychologistRespondsByPsychologistId.bind(
                null,
                apiClient,
            ),
            getPsychologistRespond: getPsychologistRespond.bind(null, apiClient),
            savePsychologistRespond: savePsychologistRespond.bind(null, apiClient),
            makePsychologistRespondShowed: makePsychologistRespondShowed.bind(null, apiClient),
        },
    }
}
