import { PsychologistDTO } from './psychologist'
import { RequestDto } from './request'

export type PsychologistRespondInputDTO = {
    text?: string
    psychologistId: number
    requestId: number
    cost?: number
}

export type PsychologistRespondDTO = PsychologistRespondInputDTO & {
    id: number
    createdAt: Date
    updatedAt: Date
    psychologist: PsychologistDTO
    request: RequestDto
    isContactShowed: boolean
    isShowed: boolean
}

export const psychologistRespondPath = 'psychologist-respond'
export const psychologistRespondShowedPath = 'showed'

export type PsychologistRespondShowedInput = {
    respondId: number
    type: 'isShowed' | 'isContactShowed'
}
