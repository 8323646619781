import React, { useEffect, useState } from 'react'
import classNames from './App.module.css'
import {
    EducationType,
    educationTypeMap,
    educationEmojiMap,
    requestStringMap,
} from '@psychology-bot/domain'
import { useQuery, ApiProvider, ApiClientConfig, useApi, getApiClient } from '@psychology-bot/api'

const config: ApiClientConfig = {
    baseURL: process.env.REACT_APP_SERVER_URL,
}

const api = getApiClient(config)

function App() {
    const [query] = useState(() => new URLSearchParams(location.search))
    const api = useApi()

    const getPsychologistQuery = useQuery('getPsychologist', api.psychologist.getPsychologist, {
        isLazy: true,
    })

    const psychologist = getPsychologistQuery.data

    useEffect(() => {
        const Telegram = (window as any).Telegram
        const telegramId = query.get('telegram_id')
        if (!telegramId || !Telegram.WebApp.initData) {
            return
        }

        Telegram.WebApp.ready()

        Telegram.WebApp.MainButton.setText('Закрыть').show().onClick(Telegram.WebApp.close)

        Telegram.WebApp.onEvent(
            'themeChanged',
            () => (document.documentElement.className = Telegram.WebApp.colorScheme),
        )

        getPsychologistQuery.fetch(telegramId)
    }, [])

    if (getPsychologistQuery.isLoading) {
        return <div>Загрузка...</div>
    }

    if (getPsychologistQuery.isError) {
        return <div>Произошла ошибка</div>
    }

    if (!psychologist) {
        return null
    }

    // TODO change to dynamic ending
    const age = `${String(new Date().getFullYear() - psychologist.birthYear)} лет`
    const name = `${psychologist.firstname} ${psychologist.lastname}`
    const cost = requestStringMap.cost[psychologist.cost]
    const experience = `${psychologist.experience} лет`

    return (
        <div className={classNames.container}>
            {psychologist.photo && <img src={psychologist.photo.url} alt="аватар" />}
            <h3>Имя</h3>
            <p>{name}</p>
            <h3>Возраст</h3>
            <p>{age}</p>
            <h3>Опыт работы</h3>
            <p>{experience}</p>
            <h3>Стоимость</h3>
            <p>{cost}</p>
            <h3>Описание</h3>
            <p className={classNames.containerSummary}>{psychologist.summary}</p>
            {Array.isArray(psychologist.educations) && psychologist.educations.length !== 0 ? (
                <>
                    <h3>Оразование</h3>
                    <div>
                        {psychologist.educations.map(
                            ({ name, issued, photo, year, ...education }, index) => {
                                const type = education.type as EducationType
                                return (
                                    <div key={index}>
                                        <p>
                                            {educationEmojiMap[type]} {educationTypeMap[type]}
                                        </p>
                                        <p>{name}</p>
                                        <p>{issued}</p>
                                        <p>{year}</p>
                                        <div className={classNames.containerImage}>
                                            {photo && <img src={photo.url} alt={name} />}
                                        </div>
                                    </div>
                                )
                            },
                        )}
                    </div>
                </>
            ) : undefined}
        </div>
    )
}

export function Main() {
    return (
        <ApiProvider api={api}>
            <App />
        </ApiProvider>
    )
}
