export type FileDto = {
    name: string
    url: string
    telegramId: string
    type: FileType
}

export enum FileType {
    video = 'video',
    video_note = 'video_note',
    image = 'image',
}
